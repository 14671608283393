<template>
  <b-card>
    <b-card-header>
      <div class="text-primary h2">
        <feather-icon
          icon="StarIcon"
          size="25"
          class="mr-0 mr-sm-50"
          variant="primary"
        />
        <span class="d-none d-sm-inline">{{ $t('actions.modals.delete.title') }}</span>
      </div>
    </b-card-header>
    <b-card-body>

      <validation-observer
        ref="elementForm"
        #default="{ invalid }"
      >
        <b-row>
          <b-col>
            <span>{{ $t('actions.modals.delete.body') }} {{ $t(resourcesElementName + '.genre') }} {{ $t(resourcesElementName + '.name') }} {{ valueName }}</span>
          </b-col>
        </b-row>
        <b-row class="d-flex justify-content-end">
          <b-button
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            @click="hideModal()"
          >
            {{ $t('actions.cancel') }}
          </b-button>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :disabled="invalid"
            @click="save()"
          >
            {{ $t('actions.confirm') }}
          </b-button>
        </b-row>
      </validation-observer>
    </b-card-body>
  </b-card>

</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BAvatar, BRow, BCol, BBadge, BFormInput, BFormGroup, BForm, BFormSelect,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  components: {
    VueTimepicker, BCard, BButton, BRow, BCol, BAvatar, BBadge, BFormInput, BCardHeader, BCardBody, BFormGroup, BForm, BFormSelect, ValidationProvider, ValidationObserver,
  },
  props: {
    valueName: {
      type: String,
      required: true,
    },
    resourcesElementName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  methods: {
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'modal-1')
    },
    save() {
      this.$root.$emit('bv::hide::modal', 'modal-1')
      this.$root.$emit('delete-entity')
    },
  },
}
</script>

<style>

</style>
