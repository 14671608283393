import axios from '@axios'
import SessionStorage from '@/handlers/SessionStorage'
import handlerDataSearch from '@/handlers/handlerDataSearch'


export default {
  namespaced: true,
  state: { abortController: null },
  getters: {},
  mutations: {
    SET_CONTROLLER(state, controller) {
      state.abortController = controller
    },
    CLEAR_CONTROLLER(state) {
      state.abortController = null
    },
  },
  actions: {
    addFile(ctx, { case_id, type, fileData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/digital_file/digital_file_record/case_attachment/${case_id}`, fileData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_elements_my_schedule({ commit, state }, params) {
      if (state.abortController) {
        state.abortController.abort()
        commit('CLEAR_CONTROLLER')
      }
      const controller = new AbortController()
      commit('SET_CONTROLLER', controller)

      const currentParams = handlerDataSearch(params)
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/case/case_my_positives', { params: currentParams, signal: controller.signal })
          .then(response => {
            commit('CLEAR_CONTROLLER')
            resolve(response)
          })
          .catch(error => {
            if (error.name === 'AbortError' || error.message === 'canceled') {
              console.log('Petición abortada por el usuario')
              resolve([])
            } else {
              console.log('Error en la petición', error.message)
              reject(error)
            }
            commit('CLEAR_CONTROLLER')
          })
      })
    },
    fetch_elements_encuadre(ctx, params) {
      const currentParams = handlerDataSearch(params)
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/search', { params: currentParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_elements({ commit, state }, params) {
      if (state.abortController) {
        state.abortController.abort()
        commit('CLEAR_CONTROLLER')
      }
      const controller = new AbortController()
      commit('SET_CONTROLLER', controller)

      const currentParams = handlerDataSearch(params)
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/case/case_standard', { params: currentParams, signal: controller.signal })
          .then(response => {
            commit('CLEAR_CONTROLLER')
            resolve(response)
          })
          .catch(error => {
            if (error.name === 'AbortError' || error.message === 'canceled') {
              console.log('Petición abortada por el usuario')
              resolve([])
            } else {
              console.log('Error en la petición', error.message)
              reject(error)
            }
            commit('CLEAR_CONTROLLER')
          })
      })
    },
    fetch_elements_positives(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/case/case_positives', { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_elements_support(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/case/case_first_date_scheduled', { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_elements_not_located({ commit, state }, params) {
      if (state.abortController) {
        state.abortController.abort()
        commit('CLEAR_CONTROLLER')
      }
      const controller = new AbortController()
      commit('SET_CONTROLLER', controller)

      const currentParams = handlerDataSearch(params)
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/case/case_not_located', { params: currentParams, signal: controller.signal })
          .then(response => {
            commit('CLEAR_CONTROLLER')
            resolve(response)
          })
          .catch(error => {
            if (error.name === 'AbortError' || error.message === 'canceled') {
              console.log('Petición abortada por el usuario')
              resolve([])
            } else {
              console.log('Error en la petición', error.message)
              reject(error)
            }
            commit('CLEAR_CONTROLLER')
          })
      })
    },
    fetch_elements_pending_freezer(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/medical_order_freezer/case_pending_freezer', { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_other_elements(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/case/${params.patient_id}/${params.case_id}`, { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_companies(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/company', { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_services(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/service', { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    save_medical_orders(ctx, { meet_uuid, elementData }) {
      console.log('saving parameters:', meet_uuid, elementData)
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/cases/case/meet/${meet_uuid}`, elementData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    generate_final_document(ctx, { meet_uuid, template_type, notification_destination }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/case/meet/document/${meet_uuid}/${template_type}/${notification_destination}/INITIAL`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_headquarters(ctx, { company_id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/company/headquarter/${company_id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_element(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/case/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_case_date(ctx, { step_id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/process/case_date/step/${step_id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_meet_element(ctx, { meet_uuid }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/case/meet/${meet_uuid}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_meet_case_date(ctx, { case_uuid }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/process/meet/${case_uuid}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delete_element(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/cases/case/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    add_element(ctx, elementData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/cases/case', elementData.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    save_element(ctx, elementData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/cases/case/${elementData.data.id}`, elementData.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_config_notification(ctx, { step_id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/process/config_notification/step/${step_id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    send_notification(ctx, { noti_id, case_id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/process/process/send_notification/${noti_id}/${case_id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_key_value(ctx, { type, deep, parent }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/core/type_value/${type}`, {
            params: { deep: deep || 1, parent },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDepartments(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/geography', {
            params: { deep: 2 },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCities(ctx, { parent }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/geography', {
            params: { deep: 3, parent },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_arls(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/company', {
            params: {
              perPage: 500, page: 1, companyType: 'ARL', only_active: true,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_selector(ctx) {
      const session_storage = new SessionStorage('avenir', 'search-list')
      if (session_storage.isEmptyValueStorage()) {
        return session_storage.getValueStorage()
      }
      return new Promise((resolve, reject) => {
        axios.get('/api/v1/cases/selector_search', {})
          .then(response => {
            session_storage.updateObjectStorage(response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetch_company(ctx, { nit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/enterprise', {
            params: { nit },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_patient(ctx, { document_type_id, identity }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/patient_by_identity', {
            params: { document_type_id, identity },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fecth_users(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/doctor')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchImage(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/core/image/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
